import chartDashStyles from 'components/charts/constants/chartDashStyles';
import chartMarkerSymbols from 'components/charts/constants/chartMarkerSymbols';
import httpService from '../../services/http';
import chartOptionConstants from '../../components/charts/constants/chartOptionConstants';

export type Simulation = {
  created_at: string;
  created_by: string;
  finished_at: string;
  fmu: number;
  folder: string;
  id: number;
  inputsFMU: Object;
  is_interactive: boolean;
  name: string;
  output_interval: number;
  parameters: Object;
  start_time: number;
  started_at: string;
  stop_time: number;
  ui_setup_data: Object;
};

export type SvgData = {
  name: string;
  data: {
    name: string;
    data: {
      y: number[];
    };
  }[];
}[];

export type ResultData = {
  simulationId: number;
  [key: string]: { name: string; data: number[][] | SvgData }[] | number;
};

/**
 * @description Get simulation results by simulation ids
 */
export const getSimulationResults = async (ids: Array<number>): Promise<ResultData[]> => {
  const resultData: ResultData[] = [];
  for (const id of ids) {
    // eslint-disable-next-line no-await-in-loop
    const response = await httpService.get(
      `simulations/${id}/results/`,
      {
        headers: null,
      },
      {},
      true,
      'v2'
    );
    resultData.push({ simulationId: id, ...response.data });
  }
  return Promise.all(resultData);
};

/**
 * @description Get simulations by simulation ids
 */
export const getSimulations = async (ids: Array<number>): Promise<Simulation[]> => {
  const simulation = [];
  for (const id of ids) {
    // eslint-disable-next-line no-await-in-loop
    const response = await httpService.get(
      `simulations/${id}/`,
      {
        headers: null,
      },
      {},
      true,
      'v2'
    );
    simulation.push(response.data);
  }
  return Promise.all<Simulation[]>(simulation);
};

/**
 * @description Get chart data
 */
const getChartData = (chartDefinition: any, simulations: Simulation[], simulationResults: ResultData[]) => {
  if (simulations.length > 1) {
    return simulations.flatMap((simulation) => {
      const chartData =
        simulationResults.find((result) => result.simulationId === simulation.id)?.[chartDefinition.name] || [];

      return Array.isArray(chartData)
        ? chartData.map((element) => ({
            ...element,
            name: chartDefinition.type === 'interactive_svg' ? element.name : `${simulation.name} ${element.name}`,
          }))
        : [];
    });
  }

  return simulationResults[0][chartDefinition.name];
};

/**
 * Get updated chart definition items (series, cycles, vectors) for multiple simulations
 */
const getChartDefinitionUpdatedItems = (
  itemName: string,
  chartDefinition: any,
  simulations: Simulation[],
  colors: any
) =>
  simulations.flatMap((simulation, simulationIndex) =>
    chartDefinition[itemName].map((item: any, itemIndex: number) => {
      const index = itemIndex * simulations.length + simulationIndex;
      return {
        ...item,
        key: `${simulation.id} ${item.name}`,
        name: `${simulation.name} ${item.name}`,
        color: colors[index],
        dashtyle: chartDashStyles[index],
        markerSymbol: chartMarkerSymbols[index],

        // Possibly not needed
        varName: item.name,
        simName: simulation.name,
        nrSims: simulations.length,
      };
    })
  );

/**
 * Returns an updated version of the chart definition, including chart data and enriched
 * with the necessary configuration, ready to be rendered by the chart components.
 *
 * If multiple simulations are being compared, the chart data will be merged.
 */
export const getUpdatedChartDefinition = (
  chartDefinition: any,
  simulations: Simulation[],
  simulationResults?: ResultData[]
) => {
  // if (!simulationResults) {
  //   return { ...chartDefinition, data: null };
  // }
  const chartData = simulationResults ? getChartData(chartDefinition, simulations, simulationResults) : null;

  if (simulations.length === 1) {
    return { ...chartDefinition, data: chartData };
  }

  // Handle Simulation Comparison - Different chart types are handled with different merging strategies

  const colors = (chartDefinition.color || []).concat(chartOptionConstants.colors);

  // Interactive SVG: Each simulation gets its own chart
  if (chartDefinition.type === 'interactive_svg') {
    return simulations.map((simulation) => ({
      ...chartDefinition,
      data: chartData,
      simulationId: simulation.id,
    }));
  }

  // Cartesian Chart: Merge series of multiple simulations into one chart
  if (Object.keys(chartDefinition).includes('series')) {
    const updatedSeries = getChartDefinitionUpdatedItems('series', chartDefinition, simulations, colors);
    return { ...chartDefinition, series: updatedSeries, data: chartData };
  }

  if (Object.keys(chartDefinition).includes('cycles')) {
    const updatedCycles = getChartDefinitionUpdatedItems('cycles', chartDefinition, simulations, colors);
    return { ...chartDefinition, cycles: updatedCycles, data: chartData };
  }

  if (Object.keys(chartDefinition).includes('vectors')) {
    const updatedVectors = getChartDefinitionUpdatedItems('vectors', chartDefinition, simulations, colors);
    return { ...chartDefinition, vectors: updatedVectors, data: chartData };
  }

  if (Object.keys(chartDefinition).includes('categories')) {
    const updatedCategories: any = [];
    const updatedData: any = [];

    simulations.map((simulation) => {
      const groups: any = [];
      const data = simulationResults
        ? simulationResults.find((result) => result.simulationId === simulation.id)?.[chartDefinition.name] || []
        : [];

      chartDefinition.categories.map((category: any) => {
        if (category.groups.length > 1) {
          Array.isArray(data) &&
            updatedData.push({
              name: `${simulation.name} ${simulation.id}`,
              data: data?.filter((element: any) => element.name === category.name)[0]?.data,
            });
          category.groups.map((group: any) => groups.push({ ...group }));
          return;
        }
        const modifiedData: any = [];

        Array.isArray(data) &&
          data
            ?.filter((element) => element.name === category.name)
            .map((element: any) => {
              element.data.map((d: any) => modifiedData.push({ ...d, name: category.name }));
            });

        groups.push({ name: category.name, variable: category.groups[0].variable });
        updatedData.push({ name: `${simulation.name} ${simulation.id}`, data: modifiedData });
      });

      updatedCategories.push({ name: `${simulation.name} ${simulation.id}`, groups });
    });

    return { ...chartDefinition, categories: updatedCategories, data: updatedData };
  }

  return { ...chartDefinition, data: chartData };
};
