import React, { useEffect, useMemo, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useStructureContext } from 'context/StructureProvider';
import { storedAccessToken } from '../../services/http';
import { getSimulationResults, getSimulations, getUpdatedChartDefinition } from './helper';
import type { ResultData, Simulation } from './helper';

export type ContextType = {
  simulationIds: Array<number>;
  setSimulationIds: React.Dispatch<React.SetStateAction<Array<number>>>;
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  isSimulating: boolean;
  setIsSimulating: React.Dispatch<React.SetStateAction<boolean>>;
  timeIndex: number;
  setTimeIndex: React.Dispatch<React.SetStateAction<number>>;
  stopTime: Array<number>;
  outputInterval: Array<number>;
  invalidateCachedResponse: (id: number) => void;
  simulations: Array<Simulation> | [];
  charts: any;
};

export const SimulationResultsContext: React.Context<ContextType | undefined> =
  React.createContext<ContextType | undefined>(undefined);

const SimulationResultsProvider = ({ children }: { children: React.ReactNode }) => {
  const queryClient = useQueryClient();

  const { initialValues, resultCharts } = useStructureContext();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSimulating, setIsSimulating] = useState<boolean>(false);
  const [simulationIds, setSimulationIds] = useState<Array<number> | []>([]);
  const [simulations, setSimulations] = useState<Simulation[] | []>([]);
  const [stopTime, setStopTime] = useState<number[]>([1]);
  const [timeIndex, setTimeIndex] = useState<number>(0);
  const [outputInterval, setOutputInterval] = useState<number[]>([1]);

  // Fetch simulation data
  useQuery(
    ['Simulations', simulationIds],
    () => (storedAccessToken() && simulationIds.length ? getSimulations(simulationIds) : null),
    {
      onSuccess(data: Array<Simulation>) {
        if (data) {
          setStopTime(data.map((simulation) => simulation.stop_time ?? 1));
          setOutputInterval(
            data.map((simulation) => (simulation?.output_interval ? simulation.output_interval * 1000 : 1))
          );
          setSimulations(
            data.map((simulation) => ({
              ...simulation,
              // test if it works as exepcted : if all ui_setup_data is not defined, it will use the parameters
              parameters: { ...simulation.ui_setup_data, initialValues },
            }))
          );
        }
      },
    }
  );

  // Fetch simulation results
  const { data: simulationResults } = useQuery<ResultData[] | undefined>(['SimulationResults', simulationIds], () =>
    storedAccessToken() && simulationIds.length ? getSimulationResults(simulationIds) : undefined
  );

  useEffect(() => {
    setIsLoading(!simulations.length || !simulationResults?.length);
  }, [simulationResults, simulations.length]);

  /**
   * @description Chart definitions (with data if available), enriched with necessary
   * configuration, ready to be rendered by the chart components
   */
  const charts = useMemo(
    () =>
      resultCharts.flatMap((chartDefinition: any) =>
        getUpdatedChartDefinition(chartDefinition, simulations, simulationResults)
      ),
    [simulationResults, simulations, resultCharts]
  );

  const invalidateCachedResponse = (id: number) => {
    queryClient.invalidateQueries(['Simulations', id]);
    queryClient.invalidateQueries(['SimulationResults', id]);
  };

  return (
    <SimulationResultsContext.Provider
      value={{
        simulationIds,
        setSimulationIds,
        isLoading,
        setIsLoading,
        isSimulating,
        setIsSimulating,
        timeIndex,
        setTimeIndex,
        stopTime,
        outputInterval,
        invalidateCachedResponse,
        simulations,
        charts,
      }}
    >
      {children}
    </SimulationResultsContext.Provider>
  );
};

export const useSimulationResultsContext = (): ContextType => {
  const context = React.useContext(SimulationResultsContext);
  if (context === undefined) {
    throw new Error('useSimulationResultsContext must be used within a SimulationResultsProvider');
  }
  return context;
};

export default SimulationResultsProvider;
